@import "primeicons/primeicons.css";


html {
  height: 100% !important;
}

html body {
  margin:0;
  height: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

*::-webkit-scrollbar {
  width: 0.2em;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

input:focus{
  outline-style: none; 
  box-shadow: 0 0 1pt 1pt #1E9EA4;
}