// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sesionptv-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$sesionptv-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sesionptv-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sesionptv-theme: mat.m2-define-light-theme((
  color: (
    primary: $sesionptv-primary,
    accent: $sesionptv-accent,
    warn: $sesionptv-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sesionptv-theme);

@font-face {
  font-family: RalewayRegular;
  src: url(assets/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: RalewayBold;
  src: url(assets/fonts/Raleway-Bold.ttf);
}

@font-face {
  font-family: MontserratRegular;
  src: url(assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: MontserratBold;
  src: url(assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: MontserratMedium;
  src: url(assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: BigShouldersDisplayBold;
  src: url(assets/fonts/BigShouldersDisplay-Bold.ttf);
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: #00b1aa;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow{
  color: #00b1aa;
}

.mat-form-field-ripple {
  background-color: #00b1aa;
}